import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import NavbarTwo from "../components/App/NavbarTwo";
import Footer from "../components/App/Footer";
import Layout from "../components/App/Layout";
import Treatments from "../components/MachineLearningAISolutions/Services";
import TreatmentBanner from "../components/Treatments/TreatmentsBanner";

const Home = () => {
  const {
    allContentfulMainSlider: { nodes: treatments },
  } = useStaticQuery(query);
  const intl = useIntl();
  const treatmentsImage =
    intl.locale === "en"
      ? treatments[0].treatmentsImage.fluid
      : treatments[0].treatmentsImageFr.fluid ||
        treatments[0].treatmentsImage.fluid;
  const treatmentsFixedImage =
    intl.locale === "en"
      ? treatments[0].treatmentsImage.fixed.src
      : treatments[0].treatmentsImageFr.fixed.src ||
        treatments[0].treatmentsImage.fixed.src;
  const canonical =
    intl.locale === "en"
      ? `${process.env.GATSBY_MAIN_URL}/treatments`
      : `${process.env.GATSBY_MAIN_URL}/fr/treatments`;

  return (
    <Layout
      title="Treatment Page"
      canonical={canonical}
      image={treatmentsFixedImage}
    >
      <NavbarTwo />
      <TreatmentBanner treatmentsImage={treatmentsImage} />
      <Treatments />
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  {
    allContentfulMainSlider {
      nodes {
        treatmentsImage {
          fluid(quality: 100) {
            ...GatsbyContentfulFluid
          }
          fixed {
            src
          }
        }
        treatmentsImageFr {
          fluid(quality: 100) {
            ...GatsbyContentfulFluid
          }
          fixed {
            src
          }
        }
      }
    }
  }
`;

export default Home;
