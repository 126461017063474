import React from "react";
import Image from "gatsby-image";
import shape9 from '../../assets/images/shape/vector-shape9.png'
import shape10 from '../../assets/images/shape/vector-shape10.png'

const TreatmentsBanner = ({treatmentsImage}) => {
return (
    <div className="courses-details-area">
      <div className="courses-details-image">
        <Image fluid={treatmentsImage} alt="Medart-treatments Banner" />
      </div>
      <section className="testimonials-area bg-23173a pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <p>We offer variety kinds of Beauty Aesthetical Treatments using different devices with different technologies that will let you be</p>
                    <h2>The Goddess of Beauty..</h2>
                </div>
                    </div>
            <div className="vector-shape9">
                <img src={shape9} alt="testimonials" />
            </div>
            <div className="vector-shape10">
                <img src={shape10} alt="testimonials" />
            </div>
        </section>
    </div>
  );
};


export default TreatmentsBanner;
